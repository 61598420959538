<template>
  <div id="home-testimonials" class="py-5">
    <div class="mx-900 px-2 px-md-0 text-left text-md-center">
      <h3>{{ $t("website.solutions.tpay.solutiontestimonialtpay.title") }}</h3>
    </div>
    <carousel :per-page="1" :mouse-drag="true" :autoplay="true">
      <slide>
        <div
          class="testimonials-div mx-900 px-2 px-md-0 text-left text-md-center"
        >
          <p class="testimonial md-font-size mb-3 mt-4 mx-auto">
            {{ $t("website.solutions.tpay.solutiontestimonialtpay.desc1") }}
          </p>
          <img
            class="rating-stars"
            src="@/assets/img/solution-tpay/rating-stars.png"
            alt=""
          />
          <div class="clients d-flex flex-wrap align-items-center mt-4 mx-auto">
            <img
              class="pr-3"
              src="@/assets/img/solution-tpay/ksp-logo.png"
              alt=""
            />
            <h6 class="mt-4 mt-md-0">
              {{
                $t("website.solutions.tpay.solutiontestimonialtpay.merchant1")
              }}
            </h6>
          </div>
        </div></slide
      >
      <slide>
        <div
          class="testimonials-div mx-900 px-2 px-md-0 text-left text-md-center"
        >
          <p class="testimonial md-font-size mb-3 mt-4 mx-auto">
            {{ $t("website.solutions.tpay.solutiontestimonialtpay.desc2") }}
          </p>
          <img
            class="rating-stars"
            src="@/assets/img/solution-tpay/rating-stars.png"
            alt=""
          />
          <div class="clients d-flex flex-wrap align-items-center mt-4 mx-auto">
            <img
              class="pr-3"
              src="@/assets/img/solution-tpay/mhb-logo.png"
              alt=""
            />
            <h6 class="mt-4 mt-md-0">
              {{
                $t("website.solutions.tpay.solutiontestimonialtpay.merchant2")
              }}
            </h6>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/all.scss";
// @import "../../assets/styles/reset.css";

#home-testimonials {
  border-bottom: 1px solid $color-border;
}
.testimonial {
  max-width: 750px;
}

.clients {
  img {
    width: auto;
    height: 65px;
  }
  width: fit-content;
}

.rating-stars {
  width: 170px;
}
</style>
