<template>
  <div class="mx-1440" id="solutions-tpay-pg">
    <div class="hero-div mx-1250 d-flex flex-wrap justify-content-end">
      <div
        class="hero-div-left col-12 col-md-7 d-flex align-items-center pr-md-5"
      >
        <div
          class="hero-div-left-wrapper pt-3 pb-5 py-md-5 px-2 px-md-0 mt-sm-5-custom"
        >
          <img
            src="../../assets/img/tpay-logo-1.png"
            alt=""
            style="max-width: 80px"
            class="w-100"
          />
          <h2 class="h2-font-size mb-4 mt-4">
            {{ $t("website.solutions.tpay.solutiondashboardtpay.title") }}
            <span style="white-space: nowrap">t-Pay </span>
            <vue-typer
              style="color: #ff3377"
              class="color-primary typer"
              :text="runningTextMethod"
              :repeat="Infinity"
              :shuffle="false"
              initial-action="typing"
              :pre-type-delay="70"
              :type-delay="70"
              :pre-erase-delay="1000"
              :erase-delay="250"
              erase-style="select-all"
              :erase-on-complete="false"
              caret-animation="blink"
            ></vue-typer>
          </h2>
          <p
            class="md-font-size mb-4"
            v-html="
              $t('website.solutions.tpay.solutiondashboardtpay.description1')
            "
          ></p>
          <div class="btn2" @click="$router.push('/contactus')">
            <p class="btn2-font-size">
              {{ $t("website.solutions.tpay.solutiondashboardtpay.btn1") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-5 px-0"></div>
    </div>
    <div class="img-div col-12 col-md-5 px-0 h-100 text-right">
      <img class="h-100" src="@/assets/img/solution-tpay.webp" alt="" />
    </div>
  </div>

  <!-- <b-container class="pt-5 pl-lg-5 pr-lg-0 wrapper">
      <b-row class="pl-lg-3 pt-5">
        <b-col class="text-left mb-4 mb-lg-0 pl-4 pt-lg-5" lg="6">
          <img class="tag-img" src="@/assets/img/tpay-logo-1.png" alt="" />
          <h2 class="text-2">
            Help customers transact with you in less than a minute with t-Pay
            Payment Link
          </h2>
          <span class="text-3 w-100">
            Make it easy for your customers to purchase quickly and securely,
            without the need for cash or a physical card.<br />
            <br />
            All t-Pay features include a built-in checkout page for immediate
            payment collection from your customers. No website building or
            coding kills required.
          </span>
          <b-row align-h="start">
            <b-col lg="4" md="6" class="mt-5">
              <div
                class="d-flex flex-row align-items-center"
                style="height: 50px; cursor: pointer"
                v-scroll-to="'#tpaysec'"
              >
                <div
                  class="
                    rounded-circle
                    circle
                    h-100
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                  "
                  style="width: 50px"
                >
                  <img
                    class="h-50"
                    src="@/assets/img/arrow-down-line.png"
                    alt=""
                  />
                </div>
                <p class="mb-0 pl-3" style="line-height: 50px; color: #8a8a8a">
                  {{ $t("website.general.learnmorebtn") }}
                </p>
              </div>
            </b-col>
            <b-col lg="8" md="6"> </b-col>
          </b-row>
        </b-col>
        <b-col lg="6" class="pt-4 pt-lg-0 text-center px-0 pl-lg-5">
          <img
            class="img-fit-div"
            style="width: auto; max-height: 800px"
            src="@/assets/img/solution-tpay.webp"
            alt=""
          />
        </b-col>
      </b-row>
    </b-container>

    <b-container class="pt-lg-5 pl-lg-5 pr-lg-0 wrapper" id="tpaysec">
      <b-row align-v="center" class="pl-lg-3">
        <b-col lg="12" md="12" sm="12" class="mt-5 pt-2">
          <img style="width: 80px" src="" alt="" />
        </b-col>
      </b-row>
    </b-container>
    <b-container class="pt-lg-1 pl-lg-5 pr-lg-0 wrapper">
      <b-row class="pl-lg-3">
        <b-col lg="5" md="12" sm="12" class="pt-1">
          <h2 class="text-4 mb-5">
            {{ $t("website.solutions.tpay.solutiondashboardtpay.subtitle") }}
          </h2>
          <h2 class="text-5 mt-3 mb-3 pl-lg-2">
            {{ $t("website.solutions.tpay.solutiondashboardtpay.heading") }}
          </h2>
          <div
            style="
              background-color: #faf8f8;
              padding: 15px;
              border-radius: 20px;
            "
          >
            <span
              class="text-6"
              v-html="
                $t('website.solutions.tpay.solutiondashboardtpay.description2')
              "
            ></span>
          </div>
        </b-col>
        <b-col lg="7" md="8" sm="12" class="pt-1">
          <img class="tpay1" src="@/assets/img/tpay-1.png" alt="" />
        </b-col>
      </b-row>
    </b-container> -->
</template>

<script>
export default {
  computed: {
    runningTextMethod() {
      return [
        this.$t("website.solutions.tpay.solutiondashboardtpay.pl"),
        // this.$t("website.solutions.tpay.solutiondashboardtpay.store"),
        // this.$t("website.solutions.tpay.solutiondashboardtpay.invoice"),
        // this.$t("website.solutions.tpay.solutiondashboardtpay.rc"),
      ];
    },
  },
};
</script>
>

<style lang="scss" scoped>
@import "../../assets/styles/all.scss";
// @import "../../assets/styles/reset.css";

.btn2 {
  @include button2;
}

#solutions-tpay-pg {
  border-bottom: 1px solid $color-border;
}

.hero-div-left {
  // max-width: 700px;
}

.img-div {
  top: 0;
  right: 0;
  img {
    max-height: 1000px;
  }
}

@media only screen and (min-width: 768px) {
  .img-div {
    position: absolute;
  }
  #solutions-tpay-pg {
    position: relative;
    min-height: 800px;
  }
  .typer {
    white-space: nowrap;
  }
}

@media (max-width: 767.98px) {
  .mt-sm-5-custom {
    margin-top: 3rem !important;
  }
  .img-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
// .tag-img {
//   height: 50px;
// }

// .circle {
//   border: 1.5px solid #8a8a8a;
// }

// br {
//   display: none; // Hide BR tag for wider screens
// }

// @media (min-width: 576px) {
//   // or whatever you are after
//   br {
//     display: inline; // Show BR tag for narrow screens
//   }
// }

// $large: 992px;
// .div-paddings {
//   @media screen and (min-width: $large) {
//     padding: 0% 0% 0% 5%;
//   }
// }

// .text-1 {
//   font-size: 24px;
//   color: #ff3377;
//   line-height: 35px;
// }

// .text-2 {
//   font-family: Jost;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 48px;
//   margin-top: 10px;
//   @media screen and (min-width: 576px) {
//     line-height: 80px;
//   }
// }

// .text-3 {
//   font-family: Jost;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 18px;
//   line-height: 35px;
//   display: flex;
//   align-items: center;
//   letter-spacing: 0.02em;
// }

// .text-4 {
//   font-family: Jost;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 30px;
//   line-height: 43.35px;
// }

// .text-5 {
//   font-family: Jost;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 26px;
//   color: #4f4f4f;
//   margin-top: 5px;
// }

// .text-6 {
//   font-family: Jost;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 18px;
//   line-height: 35px;
//   color: #4f4f4f;
//   margin-top: 25px;
// }

// .text-7 {
//   font-family: Jost;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 30px;
//   line-height: 43.35px;
//   color: #5c52ca;
//   margin-top: 25px;
// }

// .side-bullet {
//   background-color: #faf8f8;
//   border-radius: 50%;
//   font-weight: 500;
//   font-size: 30px;
//   line-height: 43.35px;
//   color: #4f4f4f;
//   padding: 7px;
//   margin: 10px;
//   text-align: center;
//   @media screen and (max-width: 576px) {
//     max-width: 65px;
//   }
// }

// .img-size {
//   @media screen and (min-width: 576px) {
//     width: 55%;
//   }
//   @media screen and (max-width: 576px) {
//     width: 100%;
//   }
// }

// .tpay1 {
//   width: 100%;
// }

// .text-box-mobile {
//   @media screen and (min-width: 576px) {
//     margin-right: 18%;
//   }
//   @media screen and (max-width: 576px) {
//     margin-right: 0%;
//   }
// }
</style>
