<template>
  <div class="solutions nav-spacer">
    <SolutionDashboardtPay id="solutions-tpay-pg" />
    <SolutionTpayHighlights />
    <SolutionTpayTestimonial />
    <SolutionTpayVideos />
    <SolutionTpayFeatures />
    <SolutionTpayContact />

    <!-- <SolutionFeaturestPay />
    <GetStartedtPay /> -->
  </div>
</template>

<script>
import SolutionDashboardtPay from "../components/SolutionsTpayComponents/SolutionDashboardtPay.vue";
import SolutionTpayHighlights from "../components/SolutionsTpayComponents/SolutionTpayHighlights.vue";
import SolutionTpayTestimonial from "../components/SolutionsTpayComponents/SolutionTpayTestimonial.vue";
import SolutionTpayVideos from "../components/SolutionsTpayComponents/SolutionTpayVideos.vue";
import SolutionTpayFeatures from "../components/SolutionsTpayComponents/SolutionTpayFeatures.vue";
import SolutionTpayContact from "../components/SolutionsTpayComponents/SolutionTpayContact.vue";
import mixpanel from "mixpanel-browser";
// import SolutionFeaturestPay from "../components/SolutionsTpayComponents/SolutionFeaturestPay.vue";
// import GetStartedtPay from "../components/GetStartedtPay";
export default {
  name: "Solutions",
  components: {
    SolutionDashboardtPay,
    SolutionTpayHighlights,
    SolutionTpayTestimonial,
    SolutionTpayVideos,
    SolutionTpayFeatures,
    SolutionTpayContact,
    // SolutionFeaturestPay,
    // GetStartedtPay,
  },
  data() {
    return {
      title: "Tekkis Payment Solutions - Accept Payments Online",
      description:
        "Help customers make an online payment with you in less than a minute! All payment solutions include a built-in checkout page for immediate payment collection from your customers. No website building or coding skills required.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Help customers make an online payment with you in less than a minute! All payment solutions include a built-in checkout page for immediate payment collection from your customers. No website building or coding skills required.";
    },
  },
  mounted() {
    mixpanel.track_pageview({ page: "Solution | t-Pay" });
  },
};
</script>
